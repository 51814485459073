var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "ul",
    { staticClass: "selected-option-list" },
    _vm._l(_vm.contents, function (content, index) {
      return _c("li", { key: index, attrs: { id: "option" + index } }, [
        _c("span", [_vm._v(_vm._s(content))]),
        _c(
          "button",
          {
            staticClass: "delete",
            on: {
              click: function ($event) {
                $event.stopPropagation()
                return _vm.onDeleteOption(index)
              },
            },
          },
          [_c("vue-material-icon", { attrs: { name: "cancel" } })],
          1
        ),
      ])
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }