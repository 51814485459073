<template>
  <ul class="selected-option-list">
    <li v-for="(content, index) in contents" :key="index" :id="'option' + index">
      <span>{{ content }}</span>
      <button class="delete" @click.stop="onDeleteOption(index)">
        <vue-material-icon name="cancel" />
      </button>
    </li>
  </ul>
</template>
<script>
export default {
  name: 'SelectOption',
  props: ['contents', 'isOpenOptions'],
  state: [],
  data() {
    return {};
  },
  computed: {},
  created() {},
  mounted() {},
  watch: {},
  methods: {
    onDeleteOption(index) {
      this.contents.splice(index, 1);
      this.$emit('deleteContentId', index);
    },
  },
  components: {},
};
</script>
<style scoped lang="scss">
@import 'DropDown';
</style>
