<template>
  <li :class="{ 'input-and-button': info.key === 'loginId', editWidth: info.isPossibleEdit && !isRegister }">
    <slot name="label" :class="{ required: info.isRequired }" />
    <slot name="contents" v-if="!isEditing" />
    <input-box
      v-if="isInputAvailable && info.editType === 'input'"
      :type="info.type || 'text'"
      v-model="inputValueData"
      :id="info.key"
      autocomplete="off"
      :class="info.key === 'loginId'"
      :text-count="textCount"
      :maxlength="maxlength"
      :placeholder="placeholder"
      @input="onChangeValue"
    />
    <textarea-box
      v-if="isInputAvailable && info.editType === 'textarea'"
      v-model="inputValueData"
      :id="info.key"
      autocomplete="off"
      :maxlength="maxlength"
      :isDisplayLength="true"
      :placeholder="placeholder"
      @input="onChangeValue"
    />
    <radio-basic
      v-model="inputValueData"
      v-else-if="isInputAvailable && info.editType === 'radio'"
      v-for="(radio, index) in radioLabel"
      :key="index"
      :value="radio.value"
      color="blue"
    >
      {{ radio.label }}
    </radio-basic>
    <div v-else-if="isInputAvailable && info.editType === 'file'">
      <file-basic color="gray" :width="150" :height="32" id="upFileManual" accept=".pdf" @change="onSelectFile">
        찾아보기
      </file-basic>
      <div class="file-name" v-if="fileName">
        <p>{{ fileName }}</p>
        <span data-icon="cancel" @click="onDeleteFile">
          <vue-material-icon name="cancel" :size="12" />
        </span>
      </div>
    </div>
    <drop-down-multi-box
      v-else-if="isInputAvailable && info.editType === 'dropdown'"
      :content-list="inputValueData"
      :option-items-data="dropdownList"
      :id="info.key"
      :placeholder="info.placeholder"
      :description="info.description"
      :maxCount="info.maxCount"
      @setSelectedIdList="onSetSelectedIdList"
    />
    <div v-if="info.editType === 'text'" class="text-description" v-html="info.textContent" />
    <span class="overlap-button" v-if="isRegister && info.key === 'loginId'">
      <button-basic size="s" color="gray-border-1">증복확인</button-basic>
    </span>
    <span class="buttons" v-if="isSupervisorOrFactory">
      <template v-if="info.isPossibleEdit && !isRegister">
        <button-basic v-if="!isEditing" color="gray-border-1" size="ss" @click="toggleIsEditing">수정</button-basic>
        <template v-else>
          <button-basic color="gray-border-1" size="ss" @click="toggleIsEditing" :disabled="isLoading">
            취소
          </button-basic>
          <button-basic
            color="dark"
            size="ss"
            @click="onSaveInfo"
            :disabled="
              (info.isRequired && !inputValueData && inputValueData !== false) ||
              (info.isRequired && contentIdList.length === 0)
            "
            :loading-dot="isLoading"
            class="edit-save-btn"
          >
            저장
          </button-basic>
        </template>
      </template>
      <template v-else>
        <button-basic
          v-if="inputValueData === 'REJECTED' && isSupervisorOrFactory"
          color="gray-border-1"
          size="ss"
          @click="onOpenConfmsStatePopup"
        >
          재승인
        </button-basic>
        <button-basic
          v-if="info.isShowQrButton"
          size="ss"
          color="gray-border-1"
          @click="$emit('togglePopup', 'ConfirmPopup', 'REISSUE_QR_CODE')"
        >
          QR코드 재발급
        </button-basic>
      </template>
    </span>
    <span class="buttons" v-if="['alertSettings', 'notification'].includes(info.key)">
      <button-basic
        slot="button"
        color="blue-border-1"
        size="s"
        @click="onCreateAlertSettingsItem"
        :width="80"
        :height="32"
      >
        행 추가
      </button-basic>
    </span>
  </li>
</template>
<script>
import { mapGetters } from 'vuex';
import InputBox from '@/component/input/InputBox.vue';
import TextareaBox from '@/component/textarea/TextareaBox';
import DropDownMultiBox from '@/component/dropDown/DropDownMultiBox';

export default {
  name: 'DetailListRow',
  props: [
    'info',
    'inputSideButton',
    'inputValue',
    'inputKey',
    'infoValue',
    'radioLabel',
    'isRegister',
    'textCount',
    'maxlength',
    'placeholder',
    'fileName',
    'dropdownList',
    'isLoading',
  ],
  data() {
    return {
      isEditing: false,
      contentIdList: ['기본'],
      inputValueData: '',
    };
  },
  computed: {
    ...mapGetters('users', ['isSupervisorOrFactory']),
    isInputAvailable() {
      return this.isRegister || this.isEditing;
    },
  },
  created() {},
  mounted() {},
  watch: {
    isLoading() {
      this.isEditing = this.isLoading;
    },
  },
  methods: {
    toggleIsEditing() {
      if (this.isEditing) {
        this.$emit('clickCancelButton');
      }
      this.isEditing = !this.isEditing;
      this.inputValueData = this.inputValue;
      if (this.info.editType === 'textarea') {
        this.onShowTextArea();
      }
    },
    onSaveInfo() {
      this.$emit('saveInputValue', this.inputKey, this.inputValueData);
      if (this.info.key !== 'manualFileUrl') this.isEditing = false;
      if (this.info.editType === 'textarea') {
        this.onShowTextArea();
      }
    },
    onSelectFile(e) {
      this.$emit('selectUploadData', { event: e, type: 'file' });
    },
    onChangeValue() {
      if (this.isRegister) {
        this.$emit('saveChangeValue', this.inputKey, this.inputValueData);
      }
    },
    onCreateAlertSettingsItem() {
      this.$emit('createAlertSettingsItem');
    },
    onDeleteFile() {
      this.$emit('deleteUploadData', 'file');
    },
    onSetSelectedIdList(contentIdList, selectedName, id) {
      this.$emit('setSelectedIdList', contentIdList, selectedName, id);
      this.contentIdList = contentIdList;
    },
    onOpenConfmsStatePopup() {
      this.$emit('openConfmsStatePopup');
    },
    onShowTextArea() {
      this.$emit('showTextarea', this.isEditing);
    },
  },
  components: { InputBox, TextareaBox, DropDownMultiBox },
};
</script>
<style scoped lang="scss">
@import 'DetailListRow';
</style>
