<template>
  <div class="dropdown-wrapper" :class="{ 'multi-dropdown': maxCount === 0 }">
    <div
      class="input-wrapper"
      :class="{ 'exist-value': selectOptionShow, 'opened-options': isOpenOptions, 'multi-value': maxCount === 0 }"
      @click="toggleOptionList"
    >
      <label :for="id" :class="{ hide: selectOptionShow }">
        <input
          type="text"
          :id="id"
          :placeholder="placeholder"
          class="search-input"
          :class="{ hide: selectOptionShow }"
          disabled
        />
      </label>
      <SelectOption
        :contents="contentNameList"
        :isOpenOptions="isOpenOptions"
        v-show="selectOptionShow"
        @deleteContentId="onDeleteContentId"
      />
      <button class="input-right-icon" data-icon="input-right-icon">
        <vue-material-icon name="keyboard_arrow_down" @click="toggleOptionList" />
      </button>
    </div>
    <div class="dropdown-option" v-show="isOpenOptions" @mouseleave="isOpenOptions = false">
      <ul>
        <li
          v-for="(optionItem, index) in optionItemsData"
          @click="onCheckContentsLength(optionItem)"
          :key="index"
          :class="{
            disabled: disabledDropdownOption(optionItem.name),
          }"
        >
          {{ optionItem.groupName || optionItem.name }}
        </li>
      </ul>
    </div>
    <p class="description">{{ description }}</p>
  </div>
</template>

<script>
import SelectOption from './SelectOption.vue';
export default {
  name: 'DropDownMultiBox',
  props: {
    id: {
      type: String,
      require: false,
      default: '',
    },
    value: {
      type: String,
      require: false,
      default: '',
    },
    placeholder: {
      type: String,
      require: false,
      default: '',
    },
    optionItemsData: {
      type: Array,
      require: false,
      default: null,
    },
    description: {
      type: String,
      require: false,
      default: '',
    },
    maxCount: {
      type: Number,
      require: false,
      default: 0,
    },
    contentList: {
      require: false,
      default: null,
    },
  },
  data() {
    return {
      isOpenOptions: false,
      contentNameList: [],
      contentIdList: [],
    };
  },
  computed: {
    selectOptionShow() {
      return this.contentNameList.length > 0;
    },
  },
  created() {
    if (this.contentList) {
      if (Array.isArray(this.contentList)) {
        for (let i = 0; i < this.contentList.length; i++) {
          this.contentNameList.push(this.contentList[i].name || this.contentList[i].userName);
          this.contentIdList.push(this.contentList[i].id);
        }
      } else if (typeof this.contentList === 'object') {
        this.contentNameList.push(this.contentList.name || this.contentList.userName);
        this.contentIdList.push(this.contentList.id);
      }
    }
  },
  mounted() {},
  updated() {},
  watch: {},
  methods: {
    toggleOptionList() {
      if (this.optionItemsData.length !== 0) {
        this.isOpenOptions = !this.isOpenOptions;
      }
    },
    onCheckContentsLength(optionItem) {
      switch (this.maxCount) {
        case 1:
          this.contentNameList = [];
          this.contentIdList = [];
          break;
        case 3:
          this.contentNameList.splice(2, 1);
          this.contentIdList.splice(2, 1);
          break;
      }
      this.onClickOption(optionItem);
    },
    onClickOption(optionItem) {
      let optionName = optionItem.groupName || optionItem.name;
      let optionId = optionItem.id;
      this.contentNameList.push(optionName);
      this.contentIdList.push(optionId);
      this.$emit('setSelectedIdList', this.contentIdList, optionName, this.id);
      if (this.maxCount === 1) this.toggleOptionList();
    },
    onDeleteContentId(index) {
      this.contentIdList.splice(index, 1);
      this.$emit('setSelectedIdList', this.contentIdList, '', this.id);
    },
    disabledDropdownOption(optionItemName) {
      switch (this.id) {
        case 'factoryCompany':
          return this.contentNameList[0] === optionItemName;
        case 'equipmentManagerIds':
        case 'equipmentManagers':
          return this.contentNameList.includes(optionItemName);
        default:
          return false;
      }
    },
  },
  components: { SelectOption },
};
</script>

<style scoped lang="scss">
@import 'DropDown';
</style>
