var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "li",
    {
      class: {
        "input-and-button": _vm.info.key === "loginId",
        editWidth: _vm.info.isPossibleEdit && !_vm.isRegister,
      },
    },
    [
      _vm._t("label"),
      !_vm.isEditing ? _vm._t("contents") : _vm._e(),
      _vm.isInputAvailable && _vm.info.editType === "input"
        ? _c("input-box", {
            class: _vm.info.key === "loginId",
            attrs: {
              type: _vm.info.type || "text",
              id: _vm.info.key,
              autocomplete: "off",
              "text-count": _vm.textCount,
              maxlength: _vm.maxlength,
              placeholder: _vm.placeholder,
            },
            on: { input: _vm.onChangeValue },
            model: {
              value: _vm.inputValueData,
              callback: function ($$v) {
                _vm.inputValueData = $$v
              },
              expression: "inputValueData",
            },
          })
        : _vm._e(),
      _vm.isInputAvailable && _vm.info.editType === "textarea"
        ? _c("textarea-box", {
            attrs: {
              id: _vm.info.key,
              autocomplete: "off",
              maxlength: _vm.maxlength,
              isDisplayLength: true,
              placeholder: _vm.placeholder,
            },
            on: { input: _vm.onChangeValue },
            model: {
              value: _vm.inputValueData,
              callback: function ($$v) {
                _vm.inputValueData = $$v
              },
              expression: "inputValueData",
            },
          })
        : _vm.isInputAvailable && _vm.info.editType === "radio"
        ? _vm._l(_vm.radioLabel, function (radio, index) {
            return _c(
              "radio-basic",
              {
                key: index,
                attrs: { value: radio.value, color: "blue" },
                model: {
                  value: _vm.inputValueData,
                  callback: function ($$v) {
                    _vm.inputValueData = $$v
                  },
                  expression: "inputValueData",
                },
              },
              [_vm._v(" " + _vm._s(radio.label) + " ")]
            )
          })
        : _vm.isInputAvailable && _vm.info.editType === "file"
        ? _c(
            "div",
            [
              _c(
                "file-basic",
                {
                  attrs: {
                    color: "gray",
                    width: 150,
                    height: 32,
                    id: "upFileManual",
                    accept: ".pdf",
                  },
                  on: { change: _vm.onSelectFile },
                },
                [_vm._v(" 찾아보기 ")]
              ),
              _vm.fileName
                ? _c("div", { staticClass: "file-name" }, [
                    _c("p", [_vm._v(_vm._s(_vm.fileName))]),
                    _c(
                      "span",
                      {
                        attrs: { "data-icon": "cancel" },
                        on: { click: _vm.onDeleteFile },
                      },
                      [
                        _c("vue-material-icon", {
                          attrs: { name: "cancel", size: 12 },
                        }),
                      ],
                      1
                    ),
                  ])
                : _vm._e(),
            ],
            1
          )
        : _vm.isInputAvailable && _vm.info.editType === "dropdown"
        ? _c("drop-down-multi-box", {
            attrs: {
              "content-list": _vm.inputValueData,
              "option-items-data": _vm.dropdownList,
              id: _vm.info.key,
              placeholder: _vm.info.placeholder,
              description: _vm.info.description,
              maxCount: _vm.info.maxCount,
            },
            on: { setSelectedIdList: _vm.onSetSelectedIdList },
          })
        : _vm._e(),
      _vm.info.editType === "text"
        ? _c("div", {
            staticClass: "text-description",
            domProps: { innerHTML: _vm._s(_vm.info.textContent) },
          })
        : _vm._e(),
      _vm.isRegister && _vm.info.key === "loginId"
        ? _c(
            "span",
            { staticClass: "overlap-button" },
            [
              _c(
                "button-basic",
                { attrs: { size: "s", color: "gray-border-1" } },
                [_vm._v("증복확인")]
              ),
            ],
            1
          )
        : _vm._e(),
      _vm.isSupervisorOrFactory
        ? _c(
            "span",
            { staticClass: "buttons" },
            [
              _vm.info.isPossibleEdit && !_vm.isRegister
                ? [
                    !_vm.isEditing
                      ? _c(
                          "button-basic",
                          {
                            attrs: { color: "gray-border-1", size: "ss" },
                            on: { click: _vm.toggleIsEditing },
                          },
                          [_vm._v("수정")]
                        )
                      : [
                          _c(
                            "button-basic",
                            {
                              attrs: {
                                color: "gray-border-1",
                                size: "ss",
                                disabled: _vm.isLoading,
                              },
                              on: { click: _vm.toggleIsEditing },
                            },
                            [_vm._v(" 취소 ")]
                          ),
                          _c(
                            "button-basic",
                            {
                              staticClass: "edit-save-btn",
                              attrs: {
                                color: "dark",
                                size: "ss",
                                disabled:
                                  (_vm.info.isRequired &&
                                    !_vm.inputValueData &&
                                    _vm.inputValueData !== false) ||
                                  (_vm.info.isRequired &&
                                    _vm.contentIdList.length === 0),
                                "loading-dot": _vm.isLoading,
                              },
                              on: { click: _vm.onSaveInfo },
                            },
                            [_vm._v(" 저장 ")]
                          ),
                        ],
                  ]
                : [
                    _vm.inputValueData === "REJECTED" &&
                    _vm.isSupervisorOrFactory
                      ? _c(
                          "button-basic",
                          {
                            attrs: { color: "gray-border-1", size: "ss" },
                            on: { click: _vm.onOpenConfmsStatePopup },
                          },
                          [_vm._v(" 재승인 ")]
                        )
                      : _vm._e(),
                    _vm.info.isShowQrButton
                      ? _c(
                          "button-basic",
                          {
                            attrs: { size: "ss", color: "gray-border-1" },
                            on: {
                              click: function ($event) {
                                return _vm.$emit(
                                  "togglePopup",
                                  "ConfirmPopup",
                                  "REISSUE_QR_CODE"
                                )
                              },
                            },
                          },
                          [_vm._v(" QR코드 재발급 ")]
                        )
                      : _vm._e(),
                  ],
            ],
            2
          )
        : _vm._e(),
      ["alertSettings", "notification"].includes(_vm.info.key)
        ? _c(
            "span",
            { staticClass: "buttons" },
            [
              _c(
                "button-basic",
                {
                  attrs: {
                    slot: "button",
                    color: "blue-border-1",
                    size: "s",
                    width: 80,
                    height: 32,
                  },
                  on: { click: _vm.onCreateAlertSettingsItem },
                  slot: "button",
                },
                [_vm._v(" 행 추가 ")]
              ),
            ],
            1
          )
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }