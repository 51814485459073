var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "dropdown-wrapper",
      class: { "multi-dropdown": _vm.maxCount === 0 },
    },
    [
      _c(
        "div",
        {
          staticClass: "input-wrapper",
          class: {
            "exist-value": _vm.selectOptionShow,
            "opened-options": _vm.isOpenOptions,
            "multi-value": _vm.maxCount === 0,
          },
          on: { click: _vm.toggleOptionList },
        },
        [
          _c(
            "label",
            { class: { hide: _vm.selectOptionShow }, attrs: { for: _vm.id } },
            [
              _c("input", {
                staticClass: "search-input",
                class: { hide: _vm.selectOptionShow },
                attrs: {
                  type: "text",
                  id: _vm.id,
                  placeholder: _vm.placeholder,
                  disabled: "",
                },
              }),
            ]
          ),
          _c("SelectOption", {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.selectOptionShow,
                expression: "selectOptionShow",
              },
            ],
            attrs: {
              contents: _vm.contentNameList,
              isOpenOptions: _vm.isOpenOptions,
            },
            on: { deleteContentId: _vm.onDeleteContentId },
          }),
          _c(
            "button",
            {
              staticClass: "input-right-icon",
              attrs: { "data-icon": "input-right-icon" },
            },
            [
              _c("vue-material-icon", {
                attrs: { name: "keyboard_arrow_down" },
                on: { click: _vm.toggleOptionList },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.isOpenOptions,
              expression: "isOpenOptions",
            },
          ],
          staticClass: "dropdown-option",
          on: {
            mouseleave: function ($event) {
              _vm.isOpenOptions = false
            },
          },
        },
        [
          _c(
            "ul",
            _vm._l(_vm.optionItemsData, function (optionItem, index) {
              return _c(
                "li",
                {
                  key: index,
                  class: {
                    disabled: _vm.disabledDropdownOption(optionItem.name),
                  },
                  on: {
                    click: function ($event) {
                      return _vm.onCheckContentsLength(optionItem)
                    },
                  },
                },
                [
                  _vm._v(
                    " " + _vm._s(optionItem.groupName || optionItem.name) + " "
                  ),
                ]
              )
            }),
            0
          ),
        ]
      ),
      _c("p", { staticClass: "description" }, [
        _vm._v(_vm._s(_vm.description)),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }